export default {
  data: {
    1: {
      emissions: {
        2005: 22,
        2006: 22,
        2007: 22,
        2008: 22,
        2009: 20,
        2010: 20,
        2011: 19,
        2012: 19,
        2013: 20,
        2014: 19,
        2015: 18,
        2016: 17,
        2017: 17,
        2018: 17,
        2019: 17,
        2020: 15,
        2021: 15,
        2022: 14,
        2023: 15,
        2024: 15,
        2025: 15,
        2026: 15,
        2027: 13,
        2028: 13,
        2029: 13,
        2030: 13
      },
      last_historical_year: 2020
    },
    2: {
      emissions: {
        2005: 12,
        2006: 12,
        2007: 10,
        2008: 10,
        2009: 10,
        2010: 10,
        2011: 10,
        2012: 10,
        2013: 10,
        2014: 9,
        2015: 9,
        2016: 9,
        2017: 9,
        2018: 9,
        2019: 9,
        2020: 9,
        2021: 9,
        2022: 9,
        2023: 9,
        2024: 9,
        2025: 7,
        2026: 7,
        2027: 7,
        2028: 7,
        2029: 7,
        2030: 7
      },
      last_historical_year: 2020
    },
    3: {
      emissions: {
        2005: 22,
        2006: 22,
        2007: 22,
        2008: 22,
        2009: 22,
        2010: 20,
        2011: 19,
        2012: 19,
        2013: 20,
        2014: 20,
        2015: 18,
        2016: 17,
        2017: 17,
        2018: 17,
        2019: 17,
        2020: 17,
        2021: 16,
        2022: 16,
        2023: 16,
        2024: 16,
        2025: 16,
        2026: 15,
        2027: 15,
        2028: 15,
        2029: 15,
        2030: 15
      },
      last_historical_year: 2020
    },
    4: {
      emissions: {
        2005: 10,
        2006: 12,
        2007: 14,
        2008: 16,
        2009: 18,
        2010: 20,
        2011: 19,
        2012: 19,
        2013: 20,
        2014: 19,
        2015: 18,
        2016: 17,
        2017: 17,
        2018: 16,
        2019: 16,
        2020: 15,
        2021: 15,
        2022: 14,
        2023: 14,
        2024: 14,
        2025: 14,
        2026: 13,
        2027: 13,
        2028: 13,
        2029: 13,
        2030: 13
      },
      last_historical_year: 2020
    },
    14: {
      emissions: {
        2005: 10,
        2006: 12,
        2007: 14,
        2008: 16,
        2009: 18,
        2010: 20,
        2011: 19,
        2012: 19,
        2013: 20,
        2014: 19,
        2015: 18,
        2016: 17,
        2017: 17,
        2018: 16,
        2019: 16,
        2020: 15,
        2021: 15,
        2022: 14,
        2023: 14,
        2024: 14,
        2025: 14,
        2026: 13,
        2027: 13,
        2028: 13,
        2029: 13,
        2030: 13
      },
      last_historical_year: 2020
    },
    21: {
      emissions: {
        2005: 10,
        2006: 12,
        2007: 14,
        2008: 16,
        2009: 18,
        2010: 20,
        2011: 19,
        2012: 19,
        2013: 20,
        2014: 19,
        2015: 18,
        2016: 17,
        2017: 17,
        2018: 16,
        2019: 16,
        2020: 15,
        2021: 15,
        2022: 14,
        2023: 14,
        2024: 14,
        2025: 14,
        2026: 13,
        2027: 13,
        2028: 13,
        2029: 13,
        2030: 13
      },
      last_historical_year: 2020
    },
    22: {
      emissions: {
        2005: 13,
        2006: 12,
        2007: 11,
        2008: 12,
        2009: 13,
        2010: 11,
        2011: 14,
        2012: 14,
        2013: 12,
        2014: 13,
        2015: 13,
        2016: 12,
        2017: 12,
        2018: 11,
        2019: 11,
        2020: 11,
        2021: 10,
        2022: 9,
        2023: 9,
        2024: 9,
        2025: 8,
        2026: 8,
        2027: 7,
        2028: 7,
        2029: 8,
        2030: 7
      },
      last_historical_year: 2020
    }
  },
  metadata: {
    unit: 'MtCO2e'
  }
};
